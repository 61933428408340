'use client';
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface LayerProps {
    open : boolean; 
    onClose: any;
    Element: any; 
    dialogContentClassName: string; 
    dialogContentComponentProps: any;
    dialogTitle: string;
    showDialogActions?: boolean;
    maxWidth?: string;
    hideLayerTitle?:boolean;
    noBackdrop?: boolean;
    isStyleQquiz?: boolean;
}

const Layer: React.FC<LayerProps> = ({ 
  open, 
  onClose, 
  Element, 
  showDialogActions=true,
  dialogTitle="", 
  dialogContentClassName, 
  dialogContentComponentProps,
  hideLayerTitle=false,
  maxWidth,
  noBackdrop=false,
  isStyleQquiz=false
 }:any) => {
  
    
  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} sx={{"& .MuiDialog-paper": {
      borderRadius: "15px"
    },"& .MuiModal-backdrop": {
      opacity: noBackdrop ? '0 !important' : '1'
    } }}>
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 1,
          }}
        >
          <CloseIcon sx={{ fontSize: "25px", fontWeight: "bolder" }} />
        </IconButton>
        {dialogTitle && !hideLayerTitle && !isStyleQquiz?
      <DialogTitle style={{textAlign: "center", color: "#fff", backgroundColor:"rgb(24, 160, 251)", fontSize: "18px"}}>
         <span style={{width:"80%" ,display: "inline-block"}}>{dialogTitle}</span>
      </DialogTitle>
       : <></>}
      <DialogContent>
         <div className={dialogContentClassName}>
            <Element {...dialogContentComponentProps} />
         </div>
      </DialogContent>
    </Dialog>
  );
};

export default Layer